import React from 'react';
import Hero from './sections/Hero'
import HowItWorks from './sections/HowItWorks'
import Form from './sections/Form'
import Benefits from './sections/Benefits'
import Rewards from './sections/Rewards'
import FAQ from './sections/FAQ'
import Footer from './sections/Footer'

export default function LandingPage(props) {
    return (
        <React.Fragment>
            <Hero />
            <HowItWorks />
            <Form />
            <Benefits />
            <Rewards/>
            <FAQ />
            <Footer />
        </React.Fragment>
    )
}