import axios from "axios";
import {isDesktop, isAndroid, isIOS} from "react-device-detect";

const apiUrl = process.env.REACT_APP_API_URL ?? "https://api.recomme.io";

const campaignId = process.env.REACT_APP_CAMPAIGN_ID;

axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';
axios.defaults.baseURL = apiUrl

const getUrl = (data) => {
    const requestData = {
        campaign_id: campaignId,
        url_variables: {
            p_cust_1: null,
            p_cust_2: null,
            p_cust_3: null,
            p_img: null,
            p_name: null,
            p_price: null,
            p_ref: null,
            p_url: null,
            r_email: null,
            r_mode: null,
            r_name: null
        }
    };

    return axios.post('/recommendation/url', {...requestData, ...data})
}

const sendChanelChoice = (refCode, channel) => {
    let requestData = {
        campaign_id: campaignId,
        ref_code: refCode,
        user_agent: navigator.userAgent.substr(0, 500),
        device: isIOS ? 'IOS' : (isAndroid ? 'Android' : (isDesktop ? 'Desktop': "Unidentified")),
        channel: channel,
    }
    return axios.post("/recommendation/proceed-channel", requestData)
}

export { getUrl, sendChanelChoice }