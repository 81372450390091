import React, {useState} from 'react';
import {
    Box,
    Typography,
    Avatar
} from "@mui/material";
import {
    EmailShareButton,
    EmailIcon,
    FacebookShareButton,
    FacebookIcon,
    LinkedinShareButton,
    LinkedinIcon,
    RedditShareButton,
    RedditIcon,
    TwitterShareButton,
    TwitterIcon,
    WhatsappShareButton,
    WhatsappIcon
} from "react-share";
import {CopyToClipboard} from 'react-copy-to-clipboard';
import { MobileView, isIOS, isAndroid } from 'react-device-detect';
import { sendChanelChoice } from '../api';
import PhoneIphoneIcon from '@mui/icons-material/PhoneIphone';
import PhoneAndroidIcon from '@mui/icons-material/PhoneAndroid';

export default function RecommendationShare(props) {
    const [copied, setCopied] = useState(false);

    const showCopyInfo = () => {
        setTimeout(() => {
            setCopied(false)
        }, 500)
        return "Skopiowano";
    }

    const choiceChanel = (channel) => {
        sendChanelChoice(props.refCode, channel)
    }

    return (
        <>
            <Box sx={{
                textAlign: "center",
                flexDirection: "row",
                mb: 2
            }}>
                <Typography sx={{mb: 2}} variant={"h6"}>
                    Skopiuj  swój link i wyślij go znajomym lub wybierz którąś z poniższych aplikacji i skorzystaj z przygotowanej przez nas wiadomości. Wiadomość możesz od razu wysłać wybranym znajomym lub opublikować w swoich social mediach
                </Typography>
                <Typography sx={{
                    mb: 1
                }} variant={"h4"}>
                    Twoj unikalny link to:
                </Typography>
                <CopyToClipboard
                    text={props.shareUrl}
                    onCopy={() => {setCopied(true); choiceChanel('COPY_LINK')}}
                >
                    <Box sx={{
                        background: "#fff",
                        border: "2px solid black",
                        p: 1,
                        cursor: "pointer",
                        borderRadius: 1,
                        display: "inline-block",
                        color: copied ? "red" : "#000"
                    }}>
                        {copied ? showCopyInfo() : props.shareUrl}
                    </Box>
                </CopyToClipboard>
                <Typography variant={"h6"} >
                    (kliknij aby skopiować)
                </Typography>
            </Box>

            <Box sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                flexWrap: "wrap"
            }} maxWidth={"sm"}>

                <MobileView>
                    <Box onClick={() => choiceChanel("SMS")} sx={{
                        pr: 1
                    }}>
                        {
                            isAndroid &&
                            <a href={"sms:?&body=" + props.campaignMessages.SMS.body + " " + props.shareUrl}
                               target="_blank"
                               rel="noreferrer">
                                <div className="">
                                    <Avatar sx={{background: "green", width:"45px", height: "45px"}}><PhoneAndroidIcon/></Avatar>
                                </div>
                            </a>
                        }

                        {
                            isIOS &&
                            <a href={"sms:&body=" + props.campaignMessages.SMS.body + " " + props.shareUrl}
                               target="_blank"
                               rel="noreferrer">
                                <div className="">
                                    <Avatar sx={{background: "green", width:"45px", height: "45px"}}><PhoneIphoneIcon/></Avatar>
                                </div>
                            </a>
                        }
                    </Box>
                </MobileView>

                <Box onClick={() => choiceChanel("EMAIL")} sx={{
                    pr: 1
                }}>
                    <EmailShareButton subject={props.campaignMessages.EMAIL.title} body={props.campaignMessages.EMAIL.body} separator={" "}
                                      url={props.shareUrl}>
                        <EmailIcon size={45} round/>
                    </EmailShareButton>
                </Box>

                <Box onClick={() => choiceChanel("FACEBOOK")} sx={{
                    pr: 1
                }}>
                    <FacebookShareButton quote={props.campaignMessages.FACEBOOK.body} url={props.shareUrl}>
                        <FacebookIcon size={45} round/>
                    </FacebookShareButton>
                </Box>

                {/*<Box sx={{*/}
                {/*    pr: 1*/}
                {/*}}>*/}
                {/*    <FacebookMessengerShareButton url={shareUrl}>*/}
                {/*        <FacebookMessengerIcon size={45} round />*/}
                {/*    </FacebookMessengerShareButton>*/}

                {/*</Box>*/}

                <Box onClick={() => choiceChanel("LINKEDIN")} sx={{
                    pr: 1
                }}>
                    <LinkedinShareButton title={props.title} summary={props.campaignMessages.LINKEDIN.body} url={props.shareUrl}>
                        <LinkedinIcon size={45} round/>
                    </LinkedinShareButton>

                </Box>
                {/*<Box sx={{*/}
                {/*    pr: 1*/}
                {/*}}>*/}
                {/*    <PinterestShareButton description={props.campaignMessages.PUBLIC_DEFAULT.body} url={props.shareUrl}>*/}
                {/*        <PinterestIcon size={45} round/>*/}
                {/*    </PinterestShareButton>*/}

                {/*</Box>*/}
                <Box onClick={() => choiceChanel("REDDIT")} sx={{
                    pr: 1
                }}>
                    <RedditShareButton title={props.campaignMessages.PUBLIC_DEFAULT.body} url={props.shareUrl}>
                        <RedditIcon size={45} round/>
                    </RedditShareButton>

                </Box>
                <Box onClick={() => choiceChanel("TWITTER")} sx={{
                    pr: 1
                }}>
                    <TwitterShareButton title={props.campaignMessages.PUBLIC_DEFAULT.body} via={props.body} url={props.shareUrl}>
                        <TwitterIcon size={45} round/>
                    </TwitterShareButton>

                </Box>
                <Box onClick={() => choiceChanel("WHATSAPP")} sx={{
                    pr: 1
                }}>
                    <WhatsappShareButton title={props.campaignMessages.WHATSAPP.body} separator={" "} url={props.shareUrl}>
                        <WhatsappIcon size={45} round/>
                    </WhatsappShareButton>
                </Box>
            </Box>
        </>
    )
}