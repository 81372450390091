import React, { useState} from 'react';
import {
    Box,
    TextField,
    Button,
    FormGroup,
    FormControlLabel,
    Checkbox,
    Collapse,
    Container, FormHelperText,
} from "@mui/material";
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import RecommendationShare from "./RecommendationShare";
import { getUrl } from '../api';
import { useFormik } from 'formik';
import * as yup from 'yup';

const campaignUrl = process.env.REACT_APP_CAMPAIGN_URL;
const termsUrl = process.env.REACT_APP_CAMPAIGN_TERMS;

const dataState = {
    loading: false,
    step: 1,
    copied: false,
    result: {
        response: {},
        errors: {},
        campaignMessages: {
            EMAIL: {title:"", body: ""},
            SMS: {title:"", body: ""},
            PUBLIC_DEFAULT: {title:"", body: ""},
            FACEBOOK: {title:"", body: ""},
            WHATSAPP: {title:"", body: ""},
            LINKEDIN: {title:"", body: ""},
        },
        shareUrl: campaignUrl,
        refCode: "",
    },
}

const validationSchema = yup.object({
    name: yup
        .string('Wpisz swoje imię.')
        .min(2, 'To pole musi zawierać minimalnie 2 znaki.')
        .max(15, 'To pole może zawierać do 15 znaków.')
        .required('To pole jest wymagane.'),
    email: yup
        .string('Wpisz swój adres email.')
        .email('Wpisz poprawny adres email.')
        .required('To pole jest wymagane.'),
    terms: yup.boolean()
        .required("To pole jest wymagane.")
        .oneOf([true], "To pole musi zostać zaakceptowane."),
    marketing_agree: yup.boolean()
})

export default function RecommendationForm(props) {
    const [state, setState] = useState(dataState);

    const form = useFormik({
        initialValues: {
            email: '',
            name: '',
            terms: false,
            marketing_agree: false,
        },
        validationSchema: validationSchema,
        onSubmit: (values) => {
            setState({...state, loading: true});
            getUrl(values).then(response => {
                if(response.status === 200 || response.status === 201) {
                    const responseData = response.data.data;
                    setState({...state,
                        loading: false,
                        result: {
                            response: responseData,
                            campaignId: responseData.campaign.id,
                            campaignMessages: {
                                EMAIL: responseData.campaign_messages.EMAIL,
                                SMS: responseData.campaign_messages.SMS,
                                PUBLIC_DEFAULT: responseData.campaign_messages.PUBLIC_DEFAULT,
                                FACEBOOK: responseData.campaign_messages.FACEBOOK,
                                WHATSAPP: responseData.campaign_messages.WHATSAPP,
                                LINKEDIN: responseData.campaign_messages.LINKEDIN,
                            },
                            shareUrl: responseData.full_default_url,
                            refCode: responseData.ref_code
                        },
                        step: 2
                    });
                }
            })
        },
    });

    const label = (
        props.termsLabel ??
            <span>Akceptuję&nbsp;
                <a
                    href={termsUrl}
                    rel="noreferrer"
                    target="_blank"
                >
              regulamin
            </a>
                &nbsp;*
        </span>
    )

    const marketingLabel = (
        props.marketingLabel ??
        <span>
            Wyrażam zgodę na przetwarzanie moich danych w celach marketingowych.
        </span>
    )


    const prevStep = () => {
        setState({...state,
            step: 1
        });
    }

    const nextStep = () => {
        setState({...state,
            step: 2
        });
    }

    return (
        <>
            <Container sx={{py: 1}} maxWidth={"sm"} disableGutters>
                {
                    process.env.NODE_ENV !== 'production' &&
                    <Box sx={{p: 2, textAlign: "center"}}>
                        Only for dev mode:
                        <button onClick={ prevStep }>
                            prev
                        </button>
                        <button onClick={ nextStep }>
                            next
                        </button>
                    </Box>
                }
                <Collapse in={state.step === 1}>
                    <Box
                        component="form"
                        onSubmit={form.handleSubmit}
                        sx={{
                            '& .MuiTextField-root': { mt: 0.5 },
                            '& .MuiButton-root': { mt: 0.5 },
                            '& .MuiCheckbox-root': { mt: 0.5, pt: 0, pb: 0}
                        }}
                        noValidate
                        autoComplete="off"
                        maxWidth={"lg"}
                    >
                            <Box sx={{}}>
                                <TextField
                                    variant="outlined"
                                    fullWidth
                                    id="name"
                                    label="Imię"
                                    value={form.values.name}
                                    onChange={form.handleChange}
                                    error={form.touched.name && Boolean(form.errors.name)}
                                    helperText={form.touched.name && form.errors.name}
                                />
                            </Box>
                            <Box sx={{mt: 2}}>
                                {/* eslint-disable-next-line */}
                                <TextField
                                    variant="outlined"
                                    fullWidth
                                    id="email"
                                    name="email"
                                    label="Email"
                                    value={form.values.email}
                                    onChange={form.handleChange}
                                    error={form.touched.email && Boolean(form.errors.email)}
                                    helperText={form.touched.email && form.errors.email}
                                />
                            </Box>
                            <Box>
                                <FormGroup>
                                    <FormControlLabel
                                        id={'terms'}
                                        name={"terms"}
                                        sx={{
                                            mt: 1
                                        }}
                                        label={label}
                                        value={form.values.terms}
                                        onChange={form.handleChange}
                                        control={<Checkbox sx={{
                                            '& .MuiSvgIcon-root': {
                                                fill: (form.touched.terms && Boolean(form.errors.terms)) ? "#f44336" : "currentColor"
                                            }
                                        }} />}
                                    />
                                        <FormHelperText
                                            error={form.touched.terms && Boolean(form.errors.terms)}
                                        >
                                            {form.touched.terms && form.errors.terms}
                                        </FormHelperText>
                                    <FormControlLabel
                                        sx={{mt: 1}}
                                        id={'marketing_agree'}
                                        name={"marketing_agree"}
                                        label={marketingLabel}
                                        value={form.values.marketing_agree}
                                        onChange={form.handleChange}
                                        control={<Checkbox sx={{
                                            '& .MuiSvgIcon-root': {
                                                fill: (form.touched.marketing_agree && Boolean(form.errors.marketing_agree)) ? "#f44336" : "currentColor"
                                            }
                                        }} />}
                                    />
                                        <FormHelperText error={form.touched.terms && Boolean(form.errors.terms)}>
                                            {form.touched.marketing_agree && form.errors.marketing_agree}
                                        </FormHelperText>
                                </FormGroup>
                            </Box>
                            <Box sx={{mt: 2}}>
                                <Button type={"submit"} variant="contained" fullWidth disabled={state.loading} sx={{ p: 1}}>
                                    { state.loading
                                        ? <div>Loading...</div>
                                        : <div>Polecam <ArrowForwardIcon sx={{fontSize: 16}} /></div>
                                    }
                                </Button>
                            </Box>
                    </Box>
                </Collapse>

                <Collapse in={state.step === 2}>
                    <RecommendationShare
                        campaignMessages={state.result.campaignMessages}
                        shareUrl={state.result.shareUrl}
                        refCode={state.result.refCode}
                        campaignId={state.result.campaignId}
                    />
                </Collapse>
            </Container>
        </>
    )
}
