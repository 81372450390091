import React from 'react';
import {Container} from "@mui/material";
import TitleHeader from "../../../components/TitleHeader";

export default function Rewards(props) {
    return (
        <div>
            <Container
                component="section"
                maxWidth="lg"
                sx={{
                    background: "#fff", 
                    py: 5
                }}>
                <TitleHeader 
                    title="Trenujesz z BeActiveTV? Dziel się motywacją i zgarnij nagrody!" 
                    subtitle="Podziel się z innymi" 
                />
                <p>
                    Wspieraj innych w osiąganiu wymarzonych celów z BeActiveTV
                    i odbierz swój prezent - zniżki do <a href={"https://sklep.bebio.pl/"} target={'_blank'} rel={"noreferrer"}>Sklepu BeBio!</a><br />
                </p>
                <p>
                    Pamiętaj, że im dłuższy pakiet wykupi zaproszona przez Ciebie osoba, tym większa będzie Twoja nagroda!
                </p>
                <br />
                <p>
                W ramach naszego Programu Poleceń możesz otrzymać:
                </p>
                <p>
                <strong>5 zł zniżki</strong> do <a href={"https://sklep.bebio.pl/"} target={'_blank'} rel={"noreferrer"}>Sklepu BeBio!</a>, jeśli osoba z Twojego polecenia
                wykupi miesięczną subskrypcję BeActiveTV.
                </p>
                <p>
                <strong>10 zł zniżki</strong> do <a href={"https://sklep.bebio.pl/"} target={'_blank'} rel={"noreferrer"}>Sklepu BeBio!</a>, jeśli osoba z Twojego polecenia
                wykupi jednorazowo dostęp do BeActiveTV na 3 miesiące lub 3-miesięczną subskrypcję odnawialną.
                </p>
                <p>
                <strong>15 zł zniżki</strong> do <a href={"https://sklep.bebio.pl/"} target={'_blank'} rel={"noreferrer"}>Sklepu BeBio!</a>, jeśli osoba z Twojego polecenia
                wykupi jednorazowo dostęp do BeActiveTV na 12 miesięcy.
                </p>
                <p>
                    Z kolei każda osoba, której polecisz BeActiveTV, otrzyma na start aż <strong>15% rabatu</strong> jeśli zakupi jednorazowy pakiet na 3 lub 12 miesięcy!
                </p>
                <p>
                Żeby wziąć udział w Programie Poleceń nie musisz instalować
                żadnych aplikacji. Wystarczy Twoja ulubiona przeglądarka
                internetowa!
                </p>
            </Container>

            <Container component="section" maxWidth="100%" sx={{background: "#e4bfe4", py: 5}}>
                <Container component="section">
                    <TitleHeader 
                        title="Zaproś znajomych do BeActiveTV!" 
                        subtitle="To bardzo proste, zobacz tutaj" 
                    />
                    <p>
                    Znasz osoby, które od lat obiecują sobie, że w końcu wskoczą
                    na matę, ale do tej pory tam nie dotarły?<br />
                    Masz znajomych, którzy zazdroszczą Ci efektów i często pytają,
                    jak mogą osiągnąć podobne rezultaty?<br />
                    A może inspirujesz innych w mediach społecznościowych i
                    chcesz zachęcić swoich obserwatorów do regularnej
                    aktywności?
                    </p>
                <p>
                    <a href={"#polec"}>Poleć</a> im BeActiveTV, dziel się superzniżką i odbieraj prezenty!
                    </p>
                <p>
                    <strong>Krok #1</strong><br />
                    Wygeneruj swój indywidualny kod polecenia.
                    </p>
                <p>
                    <strong>Krok #2</strong><br />
                    Podziel się nim ze znajomymi, używając maila, wybranego
                    komunikatora internetowego lub po prostu zrób to podczas…
                    rozmowy na żywo! Kod śmiało możesz opublikować także w
                    swoich mediach społecznościowych.
                    </p>
                <p>
                    <strong>Krok #2</strong><br />
                    Obserwuj, jak kolejne osoby dzięki Tobie ruszają po
                    wymarzone cele i odbieraj cenne nagrody!
                    </p>
                </Container>
            </Container>
        </div>
    )
}
