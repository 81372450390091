import * as React from 'react';
import Typography from '@mui/material/Typography';
import {Container} from '@mui/material';

export default function TitleHeader(props) {
    return (
        <Container sx={{py: 1}} disableGutters className={'TitleHeader'}>
            <Typography variant="h2" sx={{
                textAlign: props.position ?? "left",
                '&::after': {
                    content: `' '`,
                    display: 'block',
                    width: '90px',
                    height: '3px',
                    backgroundColor: "#e4bfe4",
                    marginTop: '-5px',
                }
            }} >
                {props.title}
            </Typography>
            <Typography variant="h5" sx={{ color: 'text.secondary', textAlign:  props.position ?? "left" , pb: props.paddingBottom ?? 5}} >
                {props.subtitle}
            </Typography>
        </Container>
    );
}