import React from 'react';
import {Container, Grid, Typography, Divider, Paper} from "@mui/material";
import TitleHeader from "../../../components/TitleHeader";

export default function Benefits(props) {
    const text = {
        title: "Co zyskasz dołączając do Programu Poleceń?",
        subtitle: "Wspierasz bliskie Ci osoby w dbaniu o zdrowie i osiąganiu wymarzonych efektów!",
        content: [
            "Zyskujesz realne nagrody, które pomogą Ci w walce o lepszą wersję siebie!",
            "Wspierasz bliskie Ci osoby w dbaniu o zdrowie i osiąganiu wymarzonych efektów!",
            "Umożliwisz innym zakup dostępu do BeActiveTV z atrakcyjną zniżką!"
        ]
    };

    return (
        <>
            <Container component="section" 
                sx={{ 
                    background: "#e4bfe4", 
                    py: 5
                }} 
                maxWidth="100%" >
                <Container component="section" sx={{ }} maxWidth="lg">
                    <TitleHeader 
                        title={text.title}
                        subtitle={text.subtitle} 
                    />
                </Container>
            </Container>
            <Container component="section" sx={{ mt: {
                xs: -9,
                lg: -5
            } }}>
                <Grid container columns={{ xs: 1, sm: 1, md: 1, lg: 12 }} rowSpacing={{ xs: 5, md: 1}} columnSpacing={{ xs: 1, sm: 6, md: 6 }} >
                    {text.content.map((val, index) => (
                        <Grid key={index} item xs={4}>
                            <Paper elevation={3} sx={{height: "160px", borderRadius: 3, background: "#fff", p: 3, display: 'flex', flexDirection: 'column', alignItems: "left"}}>
                                <Typography variant="h2" sx={{verticalAlign: "left", textAlign: "left"}}>
                                    {index+1}.
                                </Typography>
                                <Typography variant="h5" sx={{verticalAlign: "left", textAlign: "left"}}>
                                    {val}
                                </Typography>
                            </Paper>
                        </Grid>
                    ))}
                </Grid>
            </Container>
            <Divider sx={{py:5}}></Divider>
        </>
    )
}
