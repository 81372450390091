import React from 'react';
import {Container} from "@mui/material";
import ControlledAccordions from "../../../components/Accordion";
import TitleHeader from "../../../components/TitleHeader";
import faq from '../faq.json';

export default function FAQ(props) {

    // const text = faq

    return (
        <Container
            sx={{
                py: 5
            }}
            maxWidth="lg"
        >
            <TitleHeader 
                title="FAQ" 
                subtitle="Najczęściej zadawane pytania">
            </TitleHeader>
            <ControlledAccordions text={faq}/>
        </Container>
    )
}
