import * as React from "react";
// import { Routes, Route } from "react-router-dom";
import LandingPage from "./views/landingPage/LandingPage";
import "./App.css";
import { ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import theme from './views/theme';
// import {Helmet} from "react-helmet";

function App() {

    return (
        <div className="App">
            {/*<Helmet>*/}
            {/*    <meta charSet="utf-8" />*/}
            {/*    /!*<title>{process.env.REACT_APP_TITLE}</title>*!/*/}
            {/*    <link rel="canonical" href="http://mysite.com/example" />*/}
            {/*</Helmet>*/}

            <ThemeProvider theme={theme}>
                <CssBaseline />
                <LandingPage />
                {/*<Routes>*/}
                {/*  <Route exact path="/" element={<LandingPage />} />*/}
                {/*  <Route path="r/:refCode" element={<Form />} />*/}
                {/*</Routes>*/}
            </ThemeProvider>
        </div>
    );
}

export default App;