import { createTheme } from '@mui/material/styles';
import { green, grey, red } from '@mui/material/colors';
import '@fontsource/inter/300.css';
import '@fontsource/inter/400.css';
import '@fontsource/inter/800.css';
import { outlinedInputClasses } from '@mui/material/OutlinedInput';

const rawTheme = createTheme({
    palette: {
        primary: {
            light: '#69696a',
            main: '#28282a',
            dark: '#1e1e1f',
        },
        secondary: {
            light: '#fff5f8',
            main: '#ff3366',
            dark: '#e62958',
        },
        warning: {
            main: '#ffc071',
            dark: '#ffb25e',
        },
        error: {
            light: red[50],
            main: red[500],
            dark: red[700],
        },
        success: {
            light: green[50],
            main: green[500],
            dark: green[700],
        },
    },
    text: {
        secondary: {
            main: 'red',
        }
    },
    typography: {
        fontFamily: "'Inter', sans-serif",
        fontSize: 14,
        fontWeightLight: 300,
        fontWeightRegular: 400,
        fontWeightMedium: 800,
    },
    components: {
        MuiButton: {
            styleOverrides: {
                root: {
                    fontSize: '1.4em',
                    fontWeight: "normal",
                    textTransform: "none"
                },
            },
        },
        MuiOutlinedInput: {
            styleOverrides: {
                root: {
                    backgroundColor: "#fff",
                    color: "#000",
                    [`& .${outlinedInputClasses.notchedOutline}`]: {
                        borderColor: "#000",
                        borderWidth: 2,
                    }
                },
            }
        },
        MuiCheckbox: {
            styleOverrides: {
                root: {
                    color: "#000"
                },
            }
        }
    }
});

const fontHeader = {
    color: rawTheme.palette.text.primary,
    fontWeight: rawTheme.typography.fontWeightRegular,
    fontFamily: "'Inter', sans-serif",
    // textTransform: 'uppercase',
};

const theme = {
    ...rawTheme,
    palette: {
        ...rawTheme.palette,
        background: {
            ...rawTheme.palette.background,
            default: rawTheme.palette.common.white,
            placeholder: grey[200],
        },
    },
    typography: {
        ...rawTheme.typography,
        fontHeader,
        h1: {
            // ...rawTheme.typography.h1,
            fontWeight: rawTheme.typography.fontWeightMedium,
            letterSpacing: 0,
            fontSize: 50,
        },
        h2: {
            // ...rawTheme.typography.h2,
            fontWeight: rawTheme.typography.fontWeightMedium,
            fontSize: 36,
        },
        h3: {
            ...rawTheme.typography.h3,
            ...fontHeader,
            fontSize: 22,
            fontWeight: rawTheme.typography.fontWeightMedium,
        },
        h4: {
            ...rawTheme.typography.h4,
            ...fontHeader,
            fontSize: 18,
            fontWeight: rawTheme.typography.fontWeightMedium,
        },
        h5: {
            ...rawTheme.typography.h5,
            ...fontHeader,
            fontSize: 16,
            fontWeight: rawTheme.typography.fontWeightRegular,
        },
        h6: {
            ...rawTheme.typography.h6,
            ...fontHeader,
            fontWeight: rawTheme.typography.fontWeightRegular,
            fontSize: 14,
        },
        subtitle1: {
            ...rawTheme.typography.subtitle1,
            fontSize: 18,
        },
        body1: {
            ...rawTheme.typography.body2,
            fontWeight: rawTheme.typography.fontWeightRegular,
            fontSize: 16,
        },
        body2: {
            ...rawTheme.typography.body1,
            fontSize: 14,
        },
    },
};

export default theme;