import React from 'react';
import {Container} from "@mui/material";
import Copyright from "../../../components/Copyright";

export default function Footer(props) {
    return (
        <Container
            sx={{
                py: 5
            }}
        >
            <Copyright />
        </Container>
    )
}
