import * as React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

export default function ControlledAccordions(params) {
    const [expanded, setExpanded] = React.useState(false);

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };
// console.log(params.text.data);
    return (
        <div>
            {
                params.text.data.map((text, index) => {
                return (
                    <Accordion key={index} expanded={expanded === index} onChange={handleChange(index)}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls={index + "bh-content"}
                            id={index + "bh-header"}
                        >
                            <Typography variant="h4" >
                                {text.title}
                            </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography variant="body1" >
                                {text.content}
                            </Typography>
                        </AccordionDetails>
                    </Accordion>
                    )
                    }
                )
            }
        </div>
    );
}