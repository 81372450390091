import React from 'react';
import {Container, Typography, Box, Grid} from "@mui/material";
import laptop from '../../../assets/img/laptop.png';
import monitor from '../../../assets/img/monitor.png';
import tablet from '../../../assets/img/tablet.png';
import logo from '../../../assets/img/beactive_logo.png';

export default function Hero(props) {
    return (
        <Container
            sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                py: 5,
                position: "relative"
            }}
            maxWidth="100%"
            className="Hero"
        >
            <Box sx={{
                display: "flex",
                alignItems:"center",
                flexDirection: {
                    xs: "column-reverse",
                    md: "row"
                }
            }} maxWidth={"lg"}>
                <Box sx={{
                    display: "flex",
                }} >
                    <Typography sx={{
                        textShadow: "2px 3px 5px rgba(0,0,0,0.5)",
                        color: "#fff"
                    }} variant={"h1"}>
                        Poleć BeActiveTV znajomym i wybierz prezent!
                    </Typography>
                </Box>
                <Box sx={{
                    width: "300px",
                    display: "flex",
                }}>
                    <img src={logo} alt={"BeActiveTV"} width={'300px'} height={'auto'}/>
                </Box>
            </Box>
            <Container
                disableGutters
                maxWidth={"lg"}>
                <Grid sx={{
                    position: "relative",
                    height: {
                        xs: "30vw",
                        lg: "350px"
                    },
                    marginTop: "140px"
                }} maxWidth={"lg"}>
                    <Box sx={{
                        zIndex: 10,
                        position: "absolute",
                        width: {
                            xs: "34.95vw",
                            lg: "400px",
                        },
                        top: {
                            xs: "-9.48vw",
                            lg: "-100px"
                        },
                        left: {
                            xs: "-2.74vw",
                            lg: "50px"
                        }
                    }}>
                        <img src={tablet} alt={"tablet"} width={"100%"} height={"auto"} />
                    </Box>
                    <Box sx={{
                        position: "absolute",
                        width: {
                            xs: "34.95vw",
                            lg: "400px"
                        },
                        top: {
                            xs: "-13.28vw",
                            lg: "-140px"
                        },
                        left: {
                            xs: "23.25vw",
                            lg: "340px"
                        }
                    }}>
                        <img src={monitor} alt={"monitor"} width={"100%"} height={"auto"}/>
                    </Box>
                    <Box sx={{
                        position: "absolute",
                        width: {
                            xs: "53.92vw",
                            lg: "600px"
                        },
                        top: {
                            xs: "-4.74vw",
                            lg: "-50px"
                        },
                        left: {
                            xs: "38.33vw",
                            lg: "520px"
                        }
                    }}>
                        <img src={laptop} alt={"laptop"} width={"100%"} height={"auto"} />
                    </Box>
                </Grid>
            </Container>
        </Container>
    )
}
