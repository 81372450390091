import React from 'react';
import {Container} from "@mui/material";
import RecommendationForm from "../../../components/RecommendationForm";
import TitleHeader from "../../../components/TitleHeader";

export default function Form(props) {
    return (
        <Container component="section"
                   sx={{
                       background: "#e4bfe4",
                       py: 5
                   }}
                   maxWidth="100%">
            <Container component="div" maxWidth="lg" id={"polec"} disableGutters>
                <TitleHeader
                    position="center"
                    title="Polecam!"
                    subtitle="Wpisz swoje dane i dołącz do programu!"
                />
                <RecommendationForm marketingLabel={"Wyrażam zgodę na kontaktowanie się ze mną przez BEBIO Sp. z o. o. w celach marketingu bezpośredniego, w tym przesyłania informacji handlowych o produktach i usługach BEBIO Sp. z o. o. za pośrednictwem poczty e-mail."} />
            </Container>
        </Container>
    )
}
