import * as React from 'react';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import RecommeLogo from '../assets/img/recomme_logo_horizontal_color.svg';

function Copyright(props) {
    return (
      <Typography variant="body2" color="text.secondary" align="center" {...props}>
        <Link color="inherit" href="https://recomme.io">
          <img width="100px" src={RecommeLogo} alt="Recomme Referrals"/>
        </Link>
          <p>
              Administratorem Twoich danych jest BEBIO Sp. z o. o., ul. Wołoska 7, 02-675 Warszawa.
              Dowiedz się <a href="https://beactivetv.recomme.io/privacy-policy/2" target="_blank" rel="noreferrer">więcej</a> o przetwarzaniu danych osobowych
              oraz sprawdź swoje <a href="https://beactivetv.recomme.io/privacy-policy/2#cookie-settings" target="_blank" rel="noreferrer">ustawienia cookies</a>.
              Jeśli masz pytania dotyczące ochrony danych osobowych napisz na: iodo@bebio.pl
          </p>
      </Typography>
    );
  }

export default Copyright;