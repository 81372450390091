import React from 'react';
import {Container, Typography, Grid, Box, Avatar, Paper} from "@mui/material";
import ShareIcon from '@mui/icons-material/Share';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import AddShoppingCartIcon from '@mui/icons-material/AddShoppingCart';
import CardGiftcardIcon from '@mui/icons-material/CardGiftcard';
import TitleHeader from "../../../components/TitleHeader";

const text = {
    title: "Jak to działa?",
    subtitle: "Zobacz jakie to proste",
    content: {
        1: "Polecasz",
        2: "Znajomy dokonuje zakupu",
        3: "Otrzymujesz nagrodę"
    },
};

export default function HowItWorks(props) {

    return (
        <Container
            component="section"
            sx={{ py: 5}}
            maxWidth="lg">
            <TitleHeader 
                title={text.title}
                subtitle={text.subtitle}
            />
            <Grid container columns={{ xs: 1, sm: 1, md: 10, lg: 11 }}>
                <Grid item xs={3}>
                    <Paper elevation={3} component="div" sx={{ borderRadius: 3, background: "#a9a7f3", p: 5, display: 'flex', flexDirection: 'column', justifyContent: "space-between", alignItems: "center" }}>
                      <Avatar sx={{mb: 4, width: 80, height: 80, background: "#ffffff"}}>
                          <ShareIcon sx={{width: 50, height: 50, fill: "#000000"}}/>
                      </Avatar>
                      <Typography variant="h3" sx={{height: 50, verticalAlign: "center", textAlign: "center"}}>
                          {text.content[1]}
                      </Typography>
                  </Paper>
                </Grid>
                <Grid item sx={{display: "flex", justifyContent: "center", alignItems: "center", flexGrow: 1}}>
                    <ArrowForwardIosIcon />
                </Grid>
                <Grid item xs={3}>
                    <Paper elevation={3}  component="div" sx={{ borderRadius: 3, background: "#a9a7f3", p: 5, display: 'flex', flexDirection: 'column', justifyContent: "space-between", alignItems: "center" }}>
                        <Avatar sx={{mb: 4, width: 80, height: 80, background: "#ffffff"}}>
                            <AddShoppingCartIcon sx={{width: 50, height: 50, fill: "#000000"}}/>
                        </Avatar>
                        <Typography variant="h3" sx={{height: 50, verticalAlign: "center", textAlign: "center"}}>
                            {text.content[2]}
                        </Typography>
                    </Paper>
                </Grid>
                <Grid item sx={{display: "flex", justifyContent: "center",alignItems: "center", flexGrow: 1}}>
                    <ArrowForwardIosIcon />
                </Grid>
                <Grid item xs={3}>
                    <Paper elevation={3}  component="div" sx={{ borderRadius: 3, background: "#a9a7f3", p: 5, display: 'flex', flexDirection: 'column', justifyContent: "space-between", alignItems: "center" }}>
                        <Avatar sx={{mb: 4, width: 80, height: 80, background: "#ffffff"}}>
                            <CardGiftcardIcon sx={{width: 50, height: 50, fill: "#000000"}}/>
                        </Avatar>
                        <Typography variant="h3" sx={{height: 50, verticalAlign: "center", textAlign: "center"}}>
                            {text.content[3]}
                        </Typography>
                    </Paper>
                </Grid>
            </Grid>
            <Box sx={{mt: 6, textAlign: "center"}}>
                (całe polecenie zajmie Ci <strong>60 sekund</strong>)
            </Box>
        </Container>
    )
}
